import React from 'react';
import { Box, Hidden } from '@material-ui/core';
import { DYNAMICK_LINK } from 'src/constants';
import BadgesByDevice from '../BadgesByDevice/BadgesByDevice';
import styles from './QRAndAppDownload.module.scss';

const APP_URLS_SP = {
  appStoreUrl: DYNAMICK_LINK.TOP_PAGE,
  playStoreUrl: DYNAMICK_LINK.TOP_PAGE,
};

const APP_URLS_PC = {
  appStoreUrl: DYNAMICK_LINK.APP_STORE,
  playStoreUrl: DYNAMICK_LINK.PLAY_STORE,
};

const TEXT = (
  <>
    その他の使えるお店は、
    <br />
    ペイディアプリからご確認いただけます。
  </>
);

export default function QRAndAppDownload({
  qr = require('./qr.png'),
  text = TEXT,
  appUrlsSp = APP_URLS_SP,
  appUrlsPc = APP_URLS_PC,
  qrUrl = DYNAMICK_LINK.TOP_PAGE,
}) {
  return (
    <Box className={styles.container}>
      <Hidden xsDown>
        <a href={qrUrl} rel="noreferrer" target="_blank">
          <img
            width={130}
            height={130}
            alt="qr"
            src={qr}
            className={styles.qr}
          />
        </a>
      </Hidden>
      <Box className={styles.right}>
        <p className={styles.text}>{text}</p>
        <Box className={styles.badgesContainer}>
          <BadgesByDevice appUrlsSp={appUrlsSp} appUrlsPc={appUrlsPc} />
        </Box>
      </Box>
    </Box>
  );
}
