import React from 'react';
import useHorizontalScrollNavigation from '../../hooks/useHorizontalScrollNavigation';
import styles from './HorizontalScrollWithNavigation.module.scss';

const HorizontalScrollNavigation = ({ contentClassName, children }) => {
  const {
    ref,
    navigationLength,
    focusedIndex,
    canShowNavigation,
    isOverflow,
  } = useHorizontalScrollNavigation({
    contentClassName,
  });

  return (
    <div
      className={styles.container}
      style={{
        width: isOverflow ? '100%' : undefined,
        // padding: isOverflow ? '0 16px' : undefined,
      }}
    >
      <div className={styles.horizontalScrollContainer}>
        <div className={styles.horizontalScrollInner} ref={ref}>
          {children}
        </div>
      </div>
      <div
        className={styles.navigationContainer}
        style={{ display: canShowNavigation ? 'flex' : 'none' }}
      >
        {[...new Array(navigationLength).fill(null)].map((_, index) => (
          <div
            className={styles.navigationDot}
            style={{
              opacity: index === focusedIndex ? 1 : 0.3,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default HorizontalScrollNavigation;
