import React from 'react';
import { useMobileSystem } from 'src/hooks';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Hidden } from '@material-ui/core';
import QrCodeImg from 'src/images/6pay/qr-code.png';
import AppStoreImg from 'src/images/badge-app-store.svg';
import GooglePlayImg from 'src/images/badge-google-play.svg';
import styles from './QrAndBadgesSwitch.module.scss';
import { trackAppDownload } from '../../utils';

// Show QR code in PC, Show badges in Mobile
export default function QrAndBadgesSwitch({
  qrCodeImg = QrCodeImg,
  url = 'https://d9r4z.app.goo.gl/6pay',
  qrUrl = 'https://d9r4z.app.goo.gl/6pay',
}) {
  const pageName =
    typeof window !== 'undefined'
      ? window?.location?.pathname?.slice(1)
      : undefined;

  const device = useMobileSystem();

  return (
    <>
      <Hidden xsDown>
        <OutboundLink
          href={qrUrl}
          target="_blank"
          rel="noopener noreferrer"
          loading="lazy"
        >
          <img alt="qr-code" src={qrCodeImg} width={184} height={184} />
        </OutboundLink>
      </Hidden>
      <Hidden smUp>
        <div className={styles.badgesContainer}>
          {device === 'iOS' && (
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              loading="lazy"
              // inserting the appropriate identifier here requires large refactoring so taking the easy way instead
              onClick={() => trackAppDownload('App Store', pageName)}
            >
              <img alt="app-store" src={AppStoreImg} width={184} height={55} />
            </a>
          )}
          {device === 'Android' && (
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              loading="lazy"
              onClick={() => trackAppDownload('Google Play', pageName)}
            >
              <img
                alt="google-play"
                src={GooglePlayImg}
                width={184}
                height={55}
              />
            </a>
          )}
        </div>
      </Hidden>
    </>
  );
}
