import { useRef, useState, useEffect, useCallback } from 'react';

const useHorizontalScrollNavigation = ({ contentClassName }) => {
  const ref = useRef();
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [contents, setContents] = useState([]);
  const [isOverflow, setIsOverflow] = useState(false);
  const [canShowNavigation, setCanShowNavigation] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const navigationLength = contents.length;

  const onChangeScrollPos = useCallback(
    (e) => {
      if (ref.current) {
        const targetRect = ref.current.getBoundingClientRect();
        const targetLeftPos = targetRect.left;
        const containerLeftPos = e.target.getBoundingClientRect().left;
        const containerRightPos = e.target.getBoundingClientRect().right;
        const windowMidPos = window.innerWidth / 2;

        if (contents.length === 0) {
          return;
        }

        const offset =
          contents[0].getBoundingClientRect().left -
          ref.current.getBoundingClientRect().left;
        const gap =
          contents.length >= 2
            ? contents[1].getBoundingClientRect().left -
              contents[0].getBoundingClientRect().right
            : 0;
        const contentWidth = contents[0].getBoundingClientRect().width;

        if (targetLeftPos > containerLeftPos - 10) {
          setFocusedIndex(0);

          return;
        }

        if (
          e.target.offsetWidth + e.target.scrollLeft >=
          e.target.scrollWidth - 10
        ) {
          setFocusedIndex(contents.length - 1);

          return;
        }

        let currentLeftPos = targetLeftPos;

        for (let i = 0; i < contents.length; i += 1) {
          let nextLeftPos;

          if (i === 0) {
            nextLeftPos = currentLeftPos + offset + contentWidth + gap / 2;
          } else if (i === contents.length - 1) {
            nextLeftPos = containerRightPos;
          } else {
            nextLeftPos = currentLeftPos + contentWidth + gap;
          }

          if (currentLeftPos <= windowMidPos && windowMidPos <= nextLeftPos) {
            setFocusedIndex(i);
          }

          currentLeftPos = nextLeftPos;
        }
      }
    },
    [ref, contents]
  );
  const onResize = useCallback(() => {
    setRefresh(true);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize, false);

    return () => {
      window.removeEventListener('resize', onResize, false);
    };
  }, [onResize]);

  useEffect(() => {
    if (ref.current) {
      const cts = contentClassName
        ? ref.current.getElementsByClassName(contentClassName)
        : ref.current.children;

      setContents(cts);
      ref.current.parentNode.addEventListener(
        'scroll',
        onChangeScrollPos,
        false
      );
      setIsOverflow(ref.current.scrollWidth >= window.innerWidth);
      setCanShowNavigation(ref.current.scrollWidth / 2 > window.innerWidth / 2);
      setRefresh(false);
    }

    return () => {
      window.removeEventListener('scroll', onChangeScrollPos, false);
    };
  }, [ref, onChangeScrollPos, contentClassName, refresh]);

  return {
    ref,
    focusedIndex,
    navigationLength,
    isOverflow,
    canShowNavigation,
  };
};

export default useHorizontalScrollNavigation;
