import React from 'react';
import { Box } from '@material-ui/core';
import { DYNAMICK_LINK } from 'src/constants';
import QRAndAppDownload from 'src/components/Shop/QRAndAppDownload';
import styles from './SectionQRAndAppDownload.module.scss';

export default function SectionQRAndAppDownload() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      py={6}
      className={styles.container}
    >
      <QRAndAppDownload
        appUrlsSp={{
          appStoreUrl: DYNAMICK_LINK.CARDPAGE,
          playStoreUrl: DYNAMICK_LINK.CARDPAGE,
        }}
        qr={require('src/images/paidycard/qr.png')}
        qrUrl={DYNAMICK_LINK.CARDQR}
        text={
          <p className={styles.text}>
            ペイディアプリを
            <br />
            ダウンロードして申し込む
          </p>
        }
      />
    </Box>
  );
}
